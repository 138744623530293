import React, {useState, Fragment} from "react";
import {Link} from "gatsby";
import {Dialog, Transition} from '@headlessui/react';
import BlockContent from "./block-content";
import {MdOutlineClose} from "react-icons/md"

function ModalJobs({jobs, i}) {
    let [isOpen,
        setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    return (
        <div key={i}>
            <button
                className="magnaBtn btn animate-pulse hover:animate-none"
                onClick={openModal}>
                <span>{jobs.jobTitle}</span>
            </button>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-50" onClose={closeModal}>
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30"/>
                    <div className="h-screen pt-nav text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                <Dialog.Overlay className="fixed inset-0"/>
                            </Transition.Child>

                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95">
                                <div
                                    className="inline-block h-full overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl">
                                    <button className="absolute m-2 top-0 right-0">
                                        <MdOutlineClose
                                            className="text-2xl text-black cursor-pointer"
                                            onClick={closeModal}/>
                                    </button>
                                    <div
                                        className="py-4 px-6 lg:px-8 bg-secondary">
                                        <h3 className="text-2xl">{jobs.jobTitle}</h3>
                                    </div>
                                    <div className="block-content px-6 py-8 lg:p-8">
                                        <BlockContent blocks={jobs._rawJobDescription || []}/>
                                    </div>
                                    <div className="px-4 lg:px-8 mb-8">
                                        <Link to="/contact">
                                            <button className="magnaBtn btn" onClick={closeModal}>
                                                <span>Contact Us</span>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </Transition.Child>
                       
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}

export default ModalJobs;