import React, {useState} from "react";
import {Link} from "gatsby";
import BlockContent from "../components/block-content";
import ModalJobs from "./modal-jobs";
import {motion} from "framer-motion"

function JobOpenings(props) {

    const [state,
        setState] = useState('apply')

    const variants = {
        visible: {
            opacity: 1,
            y: 0,
            x: 0,
            transition: {
                duration: .5
            }
        },
        hidden: {
            opacity: 0,
            y: 10
        }
    };

    const active = props.nodes[0].currentOpenings
    const isActive = active.some(c => c.active == true)

    return (
        <div className="text-center lg:text-left mb-2 lg:my-5 lg:py-5 lg:mr-20">
            <h1 className="uppercase text-4xl mb-4 font-medium">
                {props.jobs.jobsHeaderText}
            </h1>
            <h3 className="text-xl mb-6">
                {props.jobs.jobsSubHeaderText}
            </h3>
            {state === 'apply' && <>
                <button
                    className="magnaBtn btn animate-pulse hover:animate-none"
                    onClick={() => setState('show-jobs')}>
                    <span>Apply</span>
                </button>
            </>
            }
            {state === 'show-jobs' && <motion.div
                variants={variants}
                initial="hidden"
                animate={state === 'show-jobs' && 'visible'}
                className="bg-white p-5 rounded-lg shadow-lg">
                <div id="job-openings" className="flex flex-col justify-center">
                    <h4 className="text-lg font-bold mb-6">We are currently hiring for:</h4>
                    {props.nodes[0].currentOpenings.length > 0 && isActive
                        ? <ul>
                                {props
                                    .nodes[0]
                                    .currentOpenings
                                    .map((jobs, i) => (
                                        <React.Fragment key={i}>
                                            {jobs.active && <li>
                                                <ModalJobs jobs={jobs}/>
                                            </li>
                                            }
                                        </React.Fragment>
                                    ))}
                            </ul>
                        : <BlockContent blocks={props.jobs._rawNoJobsText || []}/>
                    }
                </div>
            </motion.div>}
            <div className="about-content mt-6">
                <BlockContent blocks={props.jobs._rawJobsFooterText || []}/>
                <Link to="/contact" className="btn-arrow">
                    <span>Let's Connect</span>
                </Link>
            </div>
        </div>
    )
}

export default JobOpenings;
