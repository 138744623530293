import React from "react";
import {graphql} from "gatsby";
import PageParallax from "../components/page-parallax";
import Gallery from "../components/gallery";
import JobOpenings from "../components/job-openings";

import {mapEdgesToNodes} from "../lib/helpers";
import Layout from "../components/layout";
import SEO from "../components/seo";

export const query = graphql `
query JobsPageQuery {
  sanityJobsPage {
    jobsHero {
      ...Image
    }
    jobsHeaderText
    jobsSubHeaderText
    _rawNoJobsText
    _rawJobsFooterText
  }
  allSanityJobsPage {
    edges {
      node {
        gallery {
          asset {
            gatsbyImageData
          }
        }
        currentOpenings {
          jobTitle
          _rawJobDescription
          active
        }
      }
    }
  }
}
`;

const JobsPage = props => {

    const {data} = props;
    const jobs = data.sanityJobsPage;
    const jobNodes = data && data.allSanityJobsPage && mapEdgesToNodes(data.allSanityJobsPage);

    return (
        <Layout>
            <SEO title="Jobs" image={jobs.jobsHero}/>
            <PageParallax image={jobs.jobsHero} title="Jobs"/>
            <section id="job-openings" className="py-5 px-5 my-4">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
                        <JobOpenings nodes={jobNodes} jobs={jobs}/>
                        <Gallery nodes={jobNodes}/>
                    </div>
                </div>
            </section>
        </Layout>
    )
};

export default JobsPage;
