import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import Masonry from "react-masonry-css";

function Gallery(props) {

    const breakpointColumnsObj = {
        default: 3,
        768: 1
    };

    return (
        <div id="gallery" className="flex justify-center my-5 lg:p-2">
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="masonry-grid"
                columnClassName="masonry-grid_column p-1">
                {props
                    .nodes[0]
                    .gallery
                    .map((node, i) => (
                    <div key={i} className="pb-2 break-inside">
                        <GatsbyImage
                            image={node.asset.gatsbyImageData}
                            alt="Jobs Gallery Image"/></div>
                ))}
            </Masonry>
        </div>
    )
}

export default Gallery;
